import {get} from "../http/http";
import baseUrl from "../http/baseUrl";

//获取媒体报道列表
export const getMediaReportList = (params) => {
  return get(`${baseUrl}/v1/media/list`, params, {hideLoading: true});
}

//根据id获取媒体报道详情
export const getMediaReportDetailById = (params) => {
  return get(`${baseUrl}/v1/media/detail`, params);
}
