<template>
  <div class="media-report-detail">
    <div class="mrd-main">
      <div class="mrd-title ellipse-1">
        {{detailInfo&&detailInfo.title}}
      </div>
      <div class="mrd-time">添加日期：{{detailInfo&&detailInfo.createdTime}}</div>
      <div class="mrd-body" v-html="detailInfo&&detailInfo.content"></div>
      <div class="mrd-bottom">
        <div class="mrd-bottom-tag" style="position: relative">
          <div class="mrd-bottom-tag-name">标签：</div>
          <div class="mrd-bottom-tag-circle" v-for="(item,index) in labelList" :key="index">{{item}}</div>
          <div @click="downloadFile(detailInfo.articleAttachments[0].url)" style="position: absolute;right: 0;cursor: pointer" v-if="detailInfo.articleAttachments && detailInfo.articleAttachments[0].url"><img :src="download" alt="" style="vertical-align: text-top"><span
               style="text-decoration: none;color: black">下载</span></div>
        </div>

        <div class="mdr-bottom-click">
          <div class="ellipse-1" @click="getDetail(preDetailInfo&&preDetailInfo.id)">上一篇：{{preDetailInfo&&preDetailInfo.title}}</div>
          <div class="ellipse-1"  @click="getDetail(nextDetailInfo&&nextDetailInfo.id)">下一篇：{{nextDetailInfo&&nextDetailInfo.title}}</div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

  import {getMediaReportDetailById} from "../../plugins/api/mediaReportApi";
  import download from '@/assets/image/index/download.png'
  export default {
    name: "MediaReportDetail",
    data() {
      return {
        download:download,
        detailInfo:{},
        preDetailInfo:{},
        nextDetailInfo:{},
        labelList:[]
      }
    },
    mounted() {
      this.getDetail(this.$route.query.id);
    },
    methods: {
      async downloadFile(url){
        let fileName=url;
        var x = new XMLHttpRequest();
        x.open("GET", url, true);
        x.responseType = 'blob';
        x.onload=function(e) {
          //会创建一个 DOMString，其中包含一个表示参数中给出的对象的URL。这个 URL 的生命周期和创建它的窗口中的 document 绑定。这个新的URL 对象表示指定的 File 对象或 Blob 对象。
          var url = window.URL.createObjectURL(x.response)
          var a = document.createElement('a');
          a.href = url
          a.download = fileName;
          a.click()
        }
        x.send();
      },
      async getDetail(id) {
        const params = {
          id: id
        }
        const json = await getMediaReportDetailById(params);
        if(json&&json.code===0&&json.result){
          if(json.result.nowArticleVo){
            scrollTo(0,0)
            this.detailInfo=json.result.nowArticleVo;
            this.labelList=json.result.nowArticleVo.label.split(';');
          }
          if(json.result.preArticleVo){
            this.preDetailInfo=json.result.preArticleVo;
          }
          if(json.result.nextArticleVo){
            this.nextDetailInfo=json.result.nextArticleVo
          }
        }
      }
    },

  }
</script>

<style scoped lang="css">
  .media-report-detail {
    background-color: #FAFAFA;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 60px;
  }

  .mrd-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FFFFFF;
    margin-top: 40px;
    padding: 30px 40px;
    width: 900px;
  }

  .mrd-title {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
  }

  .mrd-time {
    font-size: 12px;
    font-weight: 400;
    color: #666666;
    margin-top: 15px;
  }
  .mrd-body{
    margin-top: 15px;
  }

  .mrd-body >>> img {
    display: block;
    margin: 0 auto;
    max-width: 100% !important;
    height: auto !important;
  }

  .mrd-bottom {
    border-top: 1px #E8E8E8 solid;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .mrd-bottom-tag {
    display: flex;
    flex-direction: row;
    padding-top: 35px;
    flex-wrap: wrap;
    align-items: center;
  }

  .mrd-bottom-tag-name {
    font-size: 14px;
    font-weight: 400;
    color: #666666;
  }

  .mrd-bottom-tag-circle {
    padding: 5px;
    border: 1px solid #DCDCDC;
    border-radius: 13px;
    margin-left: 10px;
  }

  .mdr-bottom-click {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;
    margin-top: 40px;
  }

  .mdr-bottom-click div {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    flex: 0.45;
    cursor: pointer;
  }
</style>
